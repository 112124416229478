<template>
    <b-container class="liveview" fluid="" style="margin-left: 250px; width: 80%;">
        <b-container
            class="pt-3 pb-2 mb-3 border-bottom border-dark">
            <b-form-row>
                <b-col>
                    <h1 class="h2">User Management</h1>
                </b-col>
            </b-form-row>
        </b-container>

        <b-container>
            <b-row>
                <b-col>
                    <b-list-group>
                        <b-list-group-item variant="dark" text-variant="light" style="font-weight: bold">
                            <b-row style="max-height: 1.5em; font-size: 1.1em">
                                <b-col cols="1">
                                    {{ listHeader[0].ID }}
                                </b-col>
                                <b-col cols="4">
                                    {{ listHeader[0].Nutzername }}
                                </b-col>
                                <b-col cols="3">
                                    {{ listHeader[0].Firma }}
                                </b-col>
                                <b-col cols="3">
                                    {{ listHeader[0].Nutzerlevel }}
                                </b-col>
                                <b-col cols="1">
                                    <b-icon icon="trash" style="width: 30px; height: 30px;"/>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                        <b-list-group-item
                            v-for="item in items"
                            :key="item.ID"
                            style="font-size: 1.05em"
                        >
                            <b-row style="max-height: 1.5em">
                                <b-col cols="1" style="font-weight: bold">
                                    {{ item.ID }}
                                </b-col>
                                <b-col cols="4">
                                    {{ item.Nutzername }}
                                </b-col>
                                <b-col cols="3">
                                    {{ item.Firma }}
                                </b-col>
                                <b-col cols="3">
                                    {{ item.Nutzerlevel }}
                                </b-col>
                                <b-col cols="1" class="">
                                    <b-btn
                                        pill variant="outline-dark"
                                        class="m-0 p-0"
                                        style="border-color: transparent; max-height: 26px"
                                    >
                                        <b-icon icon="x-circle-fill" style="width: 25px; height: 25px;"/>
                                    </b-btn>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-container>

    </b-container>
</template>


<script>
export default {
    data() {
        return {
            listHeader: [{ID: "ID", Nutzername: "Nutzername", Firma: "Firma", Nutzerlevel: "Nutzerlevel"},
            ],
            items: [
                {ID: 1, Nutzername: 'Max Mustermann', Firma: 'XYZ Group', Nutzerlevel: 'Administrator'},
                {ID: 2, Nutzername: 'John doe', Firma: 'XYZ Group', Nutzerlevel: 'Manager'},
                {ID: 3, Nutzername: 'Jane doe', Firma: 'XYZ Group', Nutzerlevel: 'Nutzer'},
            ]
        }
    }
};
</script>

<style>
</style>
